// import { Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';

import DesignServicesRoundedIcon from '@mui/icons-material/DesignServicesRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';

import color from 'assets/scss/_themes-vars.module.scss';

export const propertyUsesDropdownOptions = [
    {
        label: 'Commercial',
        icon: <CheckCircleRoundedIcon fontSize="small" />,
        value: 'Commercial'
    },
    {
        label: 'Residential',
        icon: <DesignServicesRoundedIcon fontSize="small" />,
        value: 'Residential'
    },
    {
        label: 'All',
        icon: <DoneRoundedIcon fontSize="small" />,
        value: 'All'
    }
];

export const propertyStatusDropdownOptions = [
    {
        label: 'Completed',
        icon: <CheckCircleRoundedIcon fontSize="small" />,
        value: 'Completed'
    },
    {
        label: 'Current',
        icon: <DesignServicesRoundedIcon fontSize="small" />,
        value: 'Current'
    },
    {
        label: 'Sold',
        icon: <DoneRoundedIcon fontSize="small" />,
        value: 'Sold'
    }
];

export const propertyProvisoryTitleDropdownOptions = [
    {
        label: 'Yes',
        icon: <CheckCircleRoundedIcon fontSize="small" />,
        value: 'Yes'
    },
    {
        label: 'No',
        icon: <DesignServicesRoundedIcon fontSize="small" />,
        value: 'No'
    }
];

export const propertyProgressStatusDropdownOptions = [
    {
        label: 'Architectural Approval',
        icon: <CheckCircleRoundedIcon fontSize="small" />,
        value: 'Architectural Approval'
    },
    {
        label: 'Reservation',
        icon: <DesignServicesRoundedIcon fontSize="small" />,
        value: 'Reservation'
    },
    {
        label: 'Promissory Contract',
        icon: <DoneRoundedIcon fontSize="small" />,
        value: 'Promissory Contract'
    },
    {
        label: 'Construction Permit',
        icon: <DoneRoundedIcon fontSize="small" />,
        value: 'Construction Permit'
    },
    {
        label: 'Breaking Stone',
        icon: <DoneRoundedIcon fontSize="small" />,
        value: 'Breaking Stone'
    },
    {
        label: 'Construction Completed',
        icon: <DoneRoundedIcon fontSize="small" />,
        value: 'Construction Completed'
    },
    {
        label: 'Use Permit Request',
        icon: <DoneRoundedIcon fontSize="small" />,
        value: 'Use Permit Request'
    },
    {
        label: 'Use Permit Granted',
        icon: <DoneRoundedIcon fontSize="small" />,
        value: 'Use Permit Granted'
    },
    {
        label: 'Section Title',
        icon: <DoneRoundedIcon fontSize="small" />,
        value: 'Section Title'
    },
    {
        label: 'Preemptive Rights',
        icon: <DoneRoundedIcon fontSize="small" />,
        value: 'Preemptive Rights'
    },
    {
        label: 'Deed and Handover',
        icon: <DoneRoundedIcon fontSize="small" />,
        value: 'Deed and Handover'
    }
];

export const quillStyleHelper = (theme: Theme) => ({
    '& .quill': {
        bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50',
        borderRadius: '12px',
        '& .ql-toolbar': {
            bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.100',
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            backgroundColor: color.grey50
        },
        '& .ql-container': {
            borderColor: theme.palette.mode === 'dark' ? `${theme.palette.dark.light + 20} !important` : 'primary.light',
            borderBottomLeftRadius: '12px',
            borderBottomRightRadius: '12px',
            backgroundColor: 'white',
            height: 'unset',
            '& .ql-editor': {
                minHeight: 135
            }
        }
    }
});
