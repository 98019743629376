import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Grid, MenuItem, Select, Stack, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import ReactQuillDemo from 'views/forms/plugins/Wysiwug/ReactQuill';

import { quillStyleHelper } from 'views/properties/Components/ProfileFormsTabs/PropertyFormTabs/helper';
import { gridSpacing } from 'store/constant';
import './styleModify.scss';
import color from 'assets/scss/_themes-vars.module.scss';
import { UserDetailsType } from 'store/users/types';
import { ModificationStatusListHelper } from '../helper';

const ModificationForms = ({ field, register, setValue, watch, control, managersList, clientsList }: { [key: string]: any }) => {
    const theme = useTheme();
    useEffect(() => {
        setValue('name', field.name);
        setValue('date', field.date);
        setValue('modification_status', field.modification_status);
        setValue('project_manager', field.project_manager);
        setValue('client_manager', field.client_manager);
        setValue('description', field?.description);
    }, [field]);

    const date = watch('date');

    return (
        <>
            <Grid container className="input-property-forms financial-forms" width={'100%'} columnSpacing={'10px'} xl={12} lg={12}>
                <>
                    <Grid item className="input-property-forms" marginBottom={'20px'} xs={12} md={6} lg={3} xl={2.4}>
                        <TextField
                            InputLabelProps={{ shrink: !!watch('name') }}
                            fullWidth
                            sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                            label="Name"
                            {...register('name')}
                        />
                    </Grid>
                    <Grid item className="input-property-forms" marginBottom={'20px'} xs={12} md={6} lg={3} xl={2.4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Date"
                                reduceAnimations
                                sx={{
                                    width: '100%',
                                    borderRadius: '12px',
                                    '.MuiInputBase-input': { fontWeight: '600' }
                                }}
                                value={dayjs(date) ?? dayjs(new Date())}
                                {...register('date')}
                                format="DD/MM/YYYY"
                                onChange={(newValue: { [key: string]: any }) => {
                                    newValue && setValue('date', newValue?.toDate()?.toISOString(), { shouldValidate: true });
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item className="input-property-forms" marginBottom={'20px'} xs={12} md={6} lg={3} xl={2.4}>
                        <Controller
                            control={control}
                            name="modification_status"
                            render={({ field: { onChange, onBlur, value, ref } }) => {
                                return (
                                    <Select
                                        key={value}
                                        labelId={value}
                                        onChange={onChange}
                                        value={value}
                                        ref={ref}
                                        onBlur={onBlur}
                                        sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                        defaultValue={'Pending'}
                                    >
                                        {ModificationStatusListHelper.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>{option.label}</div>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item className="input-property-forms" marginBottom={'20px'} xs={12} md={6} lg={3} xl={2.4}>
                        <Controller
                            control={control}
                            name="project_manager"
                            render={({ field: { onChange, onBlur, value, ref } }) => {
                                return (
                                    <Select
                                        key={value}
                                        labelId={value}
                                        onChange={onChange}
                                        value={value}
                                        ref={ref}
                                        onBlur={onBlur}
                                        sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                        defaultValue={value || managersList[0]?._id}
                                    >
                                        {managersList.map((option: UserDetailsType) => (
                                            <MenuItem value={option._id}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                    {option.first_name + ' ' + option.last_name}
                                                </div>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item className="input-property-forms" marginBottom={'20px'} xs={12} md={6} lg={3} xl={2.4}>
                        <Controller
                            control={control}
                            name="client_manager"
                            render={({ field: { onChange, onBlur, value, ref } }) => {
                                return (
                                    <Select
                                        key={value}
                                        labelId={value}
                                        onChange={onChange}
                                        value={value}
                                        ref={ref}
                                        onBlur={onBlur}
                                        sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                        defaultValue={clientsList[0]?._id}
                                    >
                                        {clientsList?.map((option: UserDetailsType) => (
                                            <MenuItem value={option._id}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                    {option.first_name + ' ' + option.last_name}
                                                </div>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item className="input-property-forms" marginBottom={'20px'} xs={12} md={12}>
                        <Grid
                            item
                            className="input-property-forms"
                            xs={12}
                            md={12}
                            sx={{ backgroundColor: color.primaryLight, borderRadius: '12px', flexDirection: 'column' }}
                            padding={'10px'}
                            marginY={'10px'}
                            display={'flex'}
                        >
                            <Stack spacing={gridSpacing} width={'100%'} sx={quillStyleHelper(theme)}>
                                <ReactQuillDemo
                                    key={field?.description}
                                    field="description"
                                    initialValue={field?.description}
                                    setValue={setValue}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </>
            </Grid>
        </>
    );
};

export default ModificationForms;
