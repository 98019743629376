// AccessControlRoute.tsx
// import { DASHBOARD_PATH } from 'config';
import useAuth from 'hooks/useAuth';
import { ReactElement, useEffect } from 'react';
import { RouteProps, useNavigate } from 'react-router-dom';

import { UserTypeEnum } from 'types/user-profile';
import routeConstant from './routeConstant';

type AccessControlRouteProps = RouteProps & {
    allowedUserTypes: UserTypeEnum[];
    children: ReactElement | null;
};

const AccessControlRoute = ({ allowedUserTypes, children }: AccessControlRouteProps) => {
    const { user , loggedInUserType} = useAuth();
    
    const navigate = useNavigate();
    useEffect(() => {
        if (!allowedUserTypes.includes(loggedInUserType as UserTypeEnum)) {
            navigate(routeConstant.accessDenied, { replace: true });
        }
    }, [user, navigate]);

    return children;
};

export default AccessControlRoute;
