import { Button, Grid, Skeleton, Typography } from '@mui/material';

import Avatar from 'ui-component/extended/Avatar';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { UploadIcon } from 'ui-component/Icons';

import './style.scss';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ImageUploadResponse, singleImageUpload } from 'services/imageUpload/imageUploadServices';
import { AxiosResponse } from 'axios';

type UploadButtonProps = {
    setValue?: any;
    register?: any;
    watch?: any;
    field?: any;
};

type ImageUrlsType = {
    imageUrl?: string;
    name?: string;
};

const UploadButton = (props: UploadButtonProps) => {
    const { setValue, register, field, watch } = props;
    const [imagePreviews, setImagePreviews] = useState<string[]>([]);
    const image = watch(field);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setImagePreviews([image]);
    }, [image]);

    const onDrop = async (acceptedFiles: File[], mediaType: any) => {
        try {
            setIsLoading(true);
            const response: AxiosResponse<ImageUploadResponse> = await singleImageUpload(acceptedFiles[0]);
            const responseData: ImageUrlsType[] = response.data?.imageUrls as ImageUrlsType[];

            if (responseData.length > 0) {
                setValue(mediaType, responseData[0]?.imageUrl as string); // Store the URLs in the form
                setImagePreviews([responseData[0]?.imageUrl] as string[]);
                setIsLoading(false);
            }
        } catch (error) {
            console.log('Error in opload single file API', error);
            throw new Error();
        } finally {
            setIsLoading(false);
        }
    };

    const { getRootProps: getRootPropsImage, getInputProps: getInputPropsImage } = useDropzone({
        onDrop: (acceptedFiles) => onDrop(acceptedFiles, field),
        accept: { 'image/*': [] }
    });

    return (
        <Grid className="upload-container" container spacing={2} alignItems="center">
            <Grid item className="avatar-upload-btn">
                {isLoading ? (
                    <Skeleton variant="circular" width={80} height={80} />
                ) : (
                    <Avatar alt="User 1" src={imagePreviews[0]} sx={{ height: 80, width: 80 }} />
                )}
            </Grid>
            <Grid {...getRootPropsImage()} item sm zeroMinWidth className="avatar-upload-description">
                <Grid container spacing={1}>
                    <input {...getInputPropsImage()} />
                    <input accept="image/*" type="hidden" {...register(field)} />
                    <Grid item xs={12} className="warning-container">
                        <Grid className="upload-btn-container">
                            <Button startIcon={UploadIcon} variant="outlined">
                                {'Upload Profile'}
                            </Button>
                        </Grid>
                        <Grid width={'80%'}>
                            <Typography variant="caption">
                                <ErrorOutlineOutlinedIcon sx={{ height: 16, width: 16, mr: 1, verticalAlign: 'text-bottom' }} />
                                Image size Limit should be 125kb Max.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UploadButton;
