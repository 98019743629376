import { useState, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Grid, Tab, Tabs, Typography } from '@mui/material';

import MainCard from 'ui-component/cards/MainCard';
import UnitFormTabs from './UnitFormTabs';

import { tabStyle } from 'views/properties/utils';
import { tabsOption } from './utils';

import color from 'assets/scss/_themes-vars.module.scss';
import './style.scss';
import { a11yProps } from 'ui-component/a11yProps/a11yProps';
import { SelectedPropertyUnitSelector } from 'store/properties/selector';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { ClientsReducer, ClientsReducerName } from 'store/clients/slice';
import { ClientsSaga, ClientsSagaName } from 'store/clients/saga';
import { UserReducer, UserReducerName } from 'store/users/slice';
import { UserSaga, UserSagaName } from 'store/users/saga';

const ModalHeader = () => {
    const theme = useTheme();

    useInjectReducer({
        reducer: ClientsReducer,
        key: ClientsReducerName
    });

    useInjectSaga({
        saga: ClientsSaga,
        key: ClientsSagaName
    });
    useInjectReducer({
        reducer: UserReducer,
        key: UserReducerName
    });

    useInjectSaga({
        saga: UserSaga,
        key: UserSagaName
    });

    const [value, setValue] = useState<number>(0);
    const selectedPropertyUnit = useSelector(SelectedPropertyUnitSelector);
    const handleChange = (_: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <MainCard sx={{ background: 'rgb(248, 245, 242)' }} className="modal-header property-forms-header-main-card">
            <Grid container paddingRight={0}>
                <Grid sx={{ background: 'white', width: '100%', overflow: 'hidden' }}>
                    <Grid padding="20px 20px 10px 20px" className="property-forms-header" item display={'flex'}>
                        <Grid width={'100%'} className="property-forms-tab-container" display="flex" justifyContent={'space-between'}>
                            <Grid item className="new-property-container" display="flex" alignItems="center">
                                <Typography sx={{ fontSize: { xs: '1rem', sm: '1.25rem' }, fontWeight: 600, color: color.grey1000 }}>
                                    Unit {selectedPropertyUnit?.unit_name} Settings
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid
                            display={'flex'}
                            alignItems={'center'}
                            flexWrap={'wrap'}
                            justifyContent={'end'}
                            className="property-forms-helper-button property-center-div"
                        >
                            <Grid
                                display={'flex'}
                                alignItems={'center'}
                                flexWrap={'wrap'}
                                width={'100%'}
                                className="property-forms-upper-tabs"
                                gap={'15px'}
                            >
                                <Tabs
                                    value={value}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={handleChange}
                                    variant="scrollable"
                                    sx={{ ...tabStyle(theme), marginBottom: '0px !important' }}
                                >
                                    {tabsOption.map((tab, index) => (
                                        <Tab
                                            className="update-sub-header-icon-button"
                                            key={index}
                                            component={Link}
                                            to="#"
                                            icon={tab.icon}
                                            label={tab.label}
                                            {...a11yProps(index)}
                                        />
                                    ))}
                                </Tabs>
                            </Grid>
                        </Grid>
                    </Grid>
                    <UnitFormTabs value={value} />
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default ModalHeader;
