export const propertyList = [
    {
        id: 1,
        unitImg: '1YxfypBujGUASvzdfa81hruOH4NNnIrUh',
        unitName: 'Santa Isabel',
        unitSubName: 'STISABEL_01',
        agency: 'Portugal Property',
        agent: 'Marcos Rios',
        client: 'Mar Pathos',
        preLaunchPrice: '€820 000,00',
        closingPrice: '€885 000,00',
        status: 'Deed'
    },
    {
        id: 2,
        unitImg: '1YxfypBujGUASvzdfa81hruOH4NNnIrUh',
        unitName: 'Santa Isabel',
        unitSubName: 'STISABEL_01',
        agency: 'Portugal Property',
        agent: 'Marcos Rios',
        client: 'Mar Pathos',
        preLaunchPrice: '€820 000,00',
        closingPrice: '€885 000,00',
        status: 'Deed'
    },
    {
        id: 3,
        unitImg: '1YxfypBujGUASvzdfa81hruOH4NNnIrUh',
        unitName: 'Santa Isabel',
        unitSubName: 'STISABEL_01',
        agency: 'Portugal Property',
        agent: 'Marcos Rios',
        client: 'Mar Pathos',
        preLaunchPrice: '€820 000,00',
        closingPrice: '€885 000,00',
        status: 'Deed'
    },
    {
        id: 4,
        unitImg: '1YxfypBujGUASvzdfa81hruOH4NNnIrUh',
        unitName: 'Santa Isabel',
        unitSubName: 'STISABEL_01',
        agency: 'Portugal Property',
        agent: 'Marcos Rios',
        client: 'Mar Pathos',
        preLaunchPrice: '€820 000,00',
        closingPrice: '€885 000,00',
        status: 'Deed'
    },
    {
        id: 5,
        unitImg: '1YxfypBujGUASvzdfa81hruOH4NNnIrUh',
        unitName: 'Santa Isabel',
        unitSubName: 'STISABEL_01',
        agency: 'Portugal Property',
        agent: 'Marcos Rios',
        client: 'Mar Pathos',
        preLaunchPrice: '€820 000,00',
        closingPrice: '€885 000,00',
        status: 'Deed'
    }
];

export const ModificationStatusListHelper = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Closed', value: 'Closed' },
    { label: 'Cancelled', value: 'Cancelled' }
];
