import { useEffect } from 'react';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ReplyIcon from '@mui/icons-material/Reply';
import ForwardOutlinedIcon from '@mui/icons-material/ForwardOutlined';
import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, TextField } from '@mui/material';
import { useFinancialsForm } from './FormHooks/useFinancialsForm';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import './style.scss';
import { fieldType } from './helper';
import { SelectedPropertyUnitSelector } from 'store/properties/selector';
import { useNavigate } from 'react-router-dom';
import { PropertiesReducerAction } from 'store/properties/slice';
import { PropertiesSagaActions } from 'store/properties/saga';

const FinancialTabs = () => {
    const { installment } = useFinancialsForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { noOfInstallments, form, paidInstallments } = installment.installmentsDetails;

    const schema = yup.object().shape({});
    const selectedPropertyUnit = useSelector(SelectedPropertyUnitSelector);
    const { register, handleSubmit, watch, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: selectedPropertyUnit && selectedPropertyUnit
    });

    const onSubmit = async (values: { [key: string]: any }) => {
        const data = {
            ...selectedPropertyUnit,
            ...values
        };
        console.log(data, 'data');
        dispatch(PropertiesSagaActions.editPropertyUnit(data));
    };

    const handleBack = () => {
        navigate(-1);
        dispatch(PropertiesReducerAction.setSelectedPropertyUnit({}));
    };

    useEffect(() => {
        if (selectedPropertyUnit) {
            reset({
                ...selectedPropertyUnit,
                pending_value: selectedPropertyUnit.pending_value || 0,
                pre_launch_price: selectedPropertyUnit.pre_launch_price || 0,
                closing_price: selectedPropertyUnit.closing_price || 0,
                paid_value: selectedPropertyUnit.paid_value || 0,
                net_value: selectedPropertyUnit.net_value || 0,
                commission_total: selectedPropertyUnit.commission_total || 0
            });
        }
    }, [selectedPropertyUnit]);

    return (
        <form>
            <Grid container className="input-property-forms financial-forms" xl={12} lg={12}>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-pre-launch-price">Pre-launch Price</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-pre-launch-price"
                            sx={{ borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                            label="Pre-launch Price"
                            type="number"
                            {...register('pre_launch_price')}
                        />
                    </FormControl>
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-closing-price">Closing Price</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-closing-price"
                            sx={{ borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                            label="Closing Price"
                            type="number"
                            {...register('closing_price')}
                        />
                    </FormControl>
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-commission-percentage">Comission %</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-commission-percentage"
                            sx={{ borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                            startAdornment={<InputAdornment position="start">%</InputAdornment>}
                            label="Comission %"
                            type="number"
                            {...register('commission_percentage')}
                        />
                    </FormControl>
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-commission-total">Comission Total</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-commission-total"
                            sx={{ borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                            label="Comission Total"
                            type="number"
                            {...register('commission_total')}
                        />
                    </FormControl>
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-vat-comission">Comission + 23% IVA</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-vat-comission"
                            sx={{ borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                            label="Comission + 23% IVA"
                            type="number"
                            {...register('vat_comission')}
                        />
                    </FormControl>
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-net-value">Value Net</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-net-value"
                            sx={{ borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                            label="Value net"
                            type="number"
                            {...register('net_value')}
                        />
                    </FormControl>
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-paid-value">Paid Value</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-paid-value"
                            sx={{ borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                            label="Paid Value"
                            type="number"
                            {...register('paid_value')}
                        />
                    </FormControl>
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-pending-value">Pending Value</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-pending-value"
                            sx={{ borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                            label="Pending Value"
                            type="number"
                            {...register('pending_value')}
                        />
                    </FormControl>
                </Grid>
                {installment.form.map((data) => (
                    <Grid
                        key={Math.random()}
                        container
                        className="input-property-forms"
                        width={'100%'}
                        xl={12}
                        lg={12}
                        spacing={{ xs: 0, sm: 1 }}
                    >
                        {data.map(({ type, ...data }) => {
                            switch (type) {
                                case fieldType.textField:
                                    return (
                                        <Grid
                                            key={Math.random()}
                                            item
                                            className="input-property-forms"
                                            marginBottom={'20px'}
                                            xs={12}
                                            sm={4}
                                        >
                                            <TextField sx={{ width: '100%' }} {...data} />
                                        </Grid>
                                    );
                                case fieldType.selectField:
                                    return (
                                        <Grid
                                            key={Math.random()}
                                            item
                                            className="input-property-forms"
                                            marginBottom={'20px'}
                                            xs={12}
                                            sm={4}
                                        >
                                            <TextField
                                                id="outlined-select-language"
                                                select
                                                fullWidth
                                                value={data.value}
                                                sx={{ width: '100%' }}
                                                onChange={() => {}}
                                            >
                                                {data?.option?.length
                                                    ? data?.option.map((option) => (
                                                          <MenuItem key={option.value} value={option.value}>
                                                              {option.label}
                                                          </MenuItem>
                                                      ))
                                                    : null}
                                            </TextField>
                                        </Grid>
                                    );
                                case fieldType.dateTime:
                                    return (
                                        <Grid key={Math.random()} item marginBottom={'20px'} xs={12} sm={4}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    value={dayjs(new Date())}
                                                    sx={{ width: '100%' }}
                                                    label={data.label}
                                                    format="ddd MMM DD YYYY"
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    );
                                default:
                                    break;
                            }
                        })}
                    </Grid>
                ))}
                <Grid key={Math.random()} container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                    {[...Array(noOfInstallments).keys()].map((_, index) => {
                        return (
                            <Grid key={Math.random()} container className="input-property-forms" width={'100%'} spacing={{ xs: 0, sm: 1 }}>
                                {form.map(({ type, ...data }) => {
                                    switch (type) {
                                        case fieldType.textField:
                                            return (
                                                <Grid key={Math.random()} item marginBottom={'20px'} xs={12} sm={4}>
                                                    <TextField sx={{ width: '100%' }} {...data} />
                                                </Grid>
                                            );
                                        case fieldType.selectField:
                                            return (
                                                <Grid
                                                    key={Math.random()}
                                                    item
                                                    className="input-property-forms"
                                                    marginBottom={'20px'}
                                                    xs={12}
                                                    sm={4}
                                                >
                                                    <TextField
                                                        id="outlined-select-language"
                                                        select
                                                        fullWidth
                                                        value={paidInstallments[index] ? 0 : 1}
                                                        sx={{ width: '100%' }}
                                                        onChange={() => {}}
                                                    >
                                                        {data?.option?.length
                                                            ? data?.option.map((option) => (
                                                                  <MenuItem key={option.value} value={option.value}>
                                                                      {option.label}
                                                                  </MenuItem>
                                                              ))
                                                            : null}
                                                    </TextField>
                                                </Grid>
                                            );
                                        case fieldType.dateTime:
                                            return (
                                                <Grid key={Math.random()} item marginBottom={'20px'} xs={12} sm={4}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            value={dayjs(new Date())}
                                                            sx={{ width: '100%' }}
                                                            format="ddd MMM DD YYYY"
                                                            label={data.label}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            );
                                        default:
                                            break;
                                    }
                                })}
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid item marginBottom={'20px'} paddingRight={{ xs: '0px', md: '10px' }} className="input-property-forms" xs={12}>
                    <TextField
                        InputLabelProps={{ shrink: !!watch('comission_notes') }}
                        fullWidth
                        sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                        label="Comission Notes"
                        {...register('comission_notes')}
                    />
                </Grid>
                <Grid marginY={'10px'} display={'flex'} gap={'20px'}>
                    <Button variant="outlined" startIcon={<ReplyIcon className="back-icon" />} onClick={handleBack}>
                        Back to Property
                    </Button>
                    <Button
                        variant="outlined"
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                        startIcon={<ForwardOutlinedIcon className="forward-icon" />}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default FinancialTabs;
