// third-party
import { FormattedMessage } from 'react-intl';
import routeConstant from 'routes/routeConstant';
import { NavItemType } from 'types';

// assets

import {
    AgentsTabIcon,
    DashboardTabIcon,
    PropertiesTabIcon,
    ClientsTabIcon,
    UpdatesTabIcon,
    DocumentsTabIcon,
    FinancialsTabIcon,
    AgenciesTabIcon,
    ArchEngTabIcon,
    ManagersTabIcon
} from 'ui-component/Icons';

import { SidebarConfig } from 'ui-component/sidebar/SidebarTabs';

// constant
const icons = {
    DashboardTabIcon,
    PropertiesTabIcon,
    ClientsTabIcon,
    UpdatesTabIcon,
    DocumentsTabIcon,
    FinancialsTabIcon,
    AgentsTabIcon,
    AgenciesTabIcon,
    ArchEngTabIcon,
    ManagersTabIcon
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const managerView: NavItemType = {
    id: 'manager',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Dashboard" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.DashboardTabIcon,
            url: routeConstant.dashboard,
            key: ['dashboard']
        },
        {
            id: 'properties',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Properties" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.PropertiesTabIcon,
            url: routeConstant.properties,
            breadcrumbs: true,
            key: ['properties', 'property']
        },
        {
            id: 'client',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Clients" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.ClientsTabIcon,
            url: routeConstant.clients,
            key: ['clients', 'client']
        },
        {
            id: 'updates',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Updates" />
                </SidebarConfig>
            ),
            type: 'item',
            url: routeConstant.updates,
            icon: icons.UpdatesTabIcon,
            key: ['updates','update']
        },
        {
            id: 'documents',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Documents" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.DocumentsTabIcon,
            url: routeConstant.documents,
            key: ['documents']
        },
        {
            id: 'financials',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Financials" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.FinancialsTabIcon,
            url: routeConstant.financials,
            key: ['financials']
        },
        {
            id: 'agents',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Agents" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.AgentsTabIcon,
            url: routeConstant.agents,
            key: ['agents', 'agent']
        },
        {
            id: 'agencies',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Agencies" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.AgenciesTabIcon,
            url: routeConstant.agencies,
            key: ['agencies', 'agency']
        },
        {
            id: 'provider',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Providers" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.ArchEngTabIcon,
            url: routeConstant.provider,
            key: ['provider']
        },
        {
            id: 'managers',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Managers" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.ManagersTabIcon,
            url: routeConstant.managers,
            key: ['managers', 'manager']
        }
    ]
};

export const agentView: any = {
    id: 'agent',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Dashboard" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.DashboardTabIcon,
            url: routeConstant.dashboard,
            key: ['dashboard']
        },
        {
            id: 'clients',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Clients" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.ClientsTabIcon,
            url: routeConstant.clients,
            key: ['clients', 'client']
        },
        {
            id: 'properties',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Properties" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.PropertiesTabIcon,
            url: routeConstant.properties,
            breadcrumbs: true,
            key: ['properties', 'property']
        },

        {
            id: 'updates',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Updates" />
                </SidebarConfig>
            ),
            type: 'item',
            url: routeConstant.updates,
            icon: icons.UpdatesTabIcon,
            key: ['updates', 'update']
        },
        {
            id: 'documents',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Documents" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.DocumentsTabIcon,
            url: routeConstant.documents,
            key: ['documents']
        },
        {
            id: 'financials',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Financials" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.FinancialsTabIcon,
            url: routeConstant.financials,
            key: ['financials']
        }
    ]
};

export const clientView: any = {
    id: 'client',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Dashboard" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.DashboardTabIcon,
            url: routeConstant.dashboard,
            key: ['dashboard']
        },
        {
            id: 'properties',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Properties" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.PropertiesTabIcon,
            url: routeConstant.properties,
            breadcrumbs: true,
            key: ['properties', 'property']
        },
        {
            id: 'updates',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Updates" />
                </SidebarConfig>
            ),
            type: 'item',
            url: routeConstant.updates,
            icon: icons.UpdatesTabIcon,
            key: ['updates', 'update']
        },
        {
            id: 'documents',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Documents" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.DocumentsTabIcon,
            url: routeConstant.documents,
            key: ['documents']
        },
        {
            id: 'financials',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Financials" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.FinancialsTabIcon,
            url: routeConstant.financials,
            key: ['financials']
        }
    ]
};

export const agencyView: any = {
    id: 'agency',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Dashboard" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.DashboardTabIcon,
            url: routeConstant.dashboard,
            key: ['dashboard']
        },
        {
            id: 'clients',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Clients" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.ClientsTabIcon,
            url: routeConstant.clients,
            key: ['clients','client']
        },
        {
            id: 'properties',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Properties" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.PropertiesTabIcon,
            url: routeConstant.properties,
            breadcrumbs: true,
            key: ['properties','property']
        },
        {
            id: 'updates',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Updates" />
                </SidebarConfig>
            ),
            type: 'item',
            url: routeConstant.updates,
            icon: icons.UpdatesTabIcon,
            key: ['updates','update']
        },
        {
            id: 'documents',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Documents" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.DocumentsTabIcon,
            url: routeConstant.documents,
            key: ['documents']
        },
        {
            id: 'financials',
            title: (
                <SidebarConfig>
                    <FormattedMessage id="Financials" />
                </SidebarConfig>
            ),
            type: 'item',
            icon: icons.FinancialsTabIcon,
            url: routeConstant.financials,
            key: ['financials']
        }
    ]
};
export default managerView;
