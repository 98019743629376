import { tabsOption } from 'views/properties/utils';
import { agentsclientsTabOption } from 'views/updates/utils';

export const getFormattedUserName = (name: string) => {
    return name.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), '');
};

export const tabUserOption = ({ loggedInUserType }: any) => {
    switch (loggedInUserType) {
        case 'ADMIN':
            return tabsOption;
        case 'MANAGER':
            return tabsOption;
        case 'AGENT':
            return agentsclientsTabOption;
        case 'CLIENT':
            return agentsclientsTabOption;
    }
};

export const userTabPanel = (loggedInUserType: any) => {
    switch (loggedInUserType) {
        case 'ADMIN':
            return true;
        case 'MANAGER':
            return true;
        case 'AGENT':
            return false;
        case 'CLIENT':
            return false;
    }
};

export const ConvertToQueryString = (data: { [key: string]: any }) =>
    JSON.stringify(data)
        .replace(/[{}]/g, '') // Remove curly braces
        .replace(/:/g, '=') // Remove curly braces
        .replace(/,/g, '&') // Replace commas with ampersands
        .replace(/"/g, '');
