import { useState } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type ReactQuillPropsType = {
    setValue?: any;
    field?: string;
    initialValue?: string;
};

const ReactQuillDemo = ({ field, setValue, initialValue }: ReactQuillPropsType) => {
    const [text, setText] = useState(initialValue);
    const handleChange = (value: string) => {
        setValue(field, value);
        setText(value);
    };
    return <ReactQuill id="description" value={text} onChange={handleChange} />;
};

export default ReactQuillDemo;
