import { SyntheticEvent, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import ReplyIcon from '@mui/icons-material/Reply';
import ForwardOutlinedIcon from '@mui/icons-material/ForwardOutlined';
import { Button, Grid, Tab, Tabs } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ModificationForms from './ModificationFroms';
import { TabPanel } from 'views/updates/utils';
import './modification-style.scss';
import { a11yProps } from 'ui-component/a11yProps/a11yProps';
import { GalleryLight } from 'ui-component/Icons';
import { isDeleteModalOpenSelector, SelectedUnitModificationSelector, UnitModificationsSelector } from 'store/properties/selector';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { PropertiesReducer, PropertiesReducerAction, PropertiesReducerName } from 'store/properties/slice';
import { PropertiesSaga, PropertiesSagaActions, PropertiesSagaName } from 'store/properties/saga';
import ConfirmationModal from 'ui-component/ConfirmationModal';
import { UserSaga, UserSagaActions, UserSagaName } from 'store/users/saga';
import { clientsListSelector, managersListSelector } from 'store/users/selector';
import { UserDetailsType } from 'store/users/types';
import { UserReducer, UserReducerName } from 'store/users/slice';

type formValues = {
    modifications: {
        id?: string;
        name: string;
        date: string;
        modification_status: string;
        project_manager: string;
        client_manager: string;
    }[];
};
const ModificationTabs = () => {
    const theme = useTheme();
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useInjectReducer({
        reducer: PropertiesReducer,
        key: PropertiesReducerName
    });

    useInjectSaga({
        saga: PropertiesSaga,
        key: PropertiesSagaName
    });

    useInjectReducer({
        reducer: UserReducer,
        key: UserReducerName
    });

    useInjectSaga({
        saga: UserSaga,
        key: UserSagaName
    });

    useEffect(() => {
        dispatch(UserSagaActions.getUserByManagerRole());
        dispatch(UserSagaActions.getUserByClientRole());
    }, []);

    useEffect(() => {
        id && dispatch(PropertiesSagaActions.fetchUnitModificationsById(id));
    }, [dispatch]);

    const managersList: UserDetailsType[] = useSelector(managersListSelector);
    const clientsList: UserDetailsType[] = useSelector(clientsListSelector);

    const handleBack = () => {
        navigate(-1);
    };
    const UnitModifcations = useSelector(UnitModificationsSelector);
    const SelectedUnitModification = useSelector(SelectedUnitModificationSelector);
    const isDeleteModalOpen = useSelector(isDeleteModalOpenSelector);

    const [value, setValues] = useState<number>(0);
    const [index, setIndex] = useState<number>(0);
    const handleChange = (_: SyntheticEvent, newValue: number) => {
        setValues(newValue);
    };

    const handleClose = () => {
        dispatch(PropertiesReducerAction.setToggleDeleteModal(false));
    };

    const handleDelete = () => {
        if (SelectedUnitModification?._id) {
            dispatch(PropertiesSagaActions.deleteUnitModificationById({ modificationId: SelectedUnitModification?._id, unitId: id }));
        }
        setValues(index - 1);
        remove(index);
        handleClose();
    };

    const modificationObj = {
        name: '',
        date: dayjs(new Date()).toISOString(),
        modification_status: 'Pending',
        project_manager: managersList[0]?._id,
        client_manager: clientsList[0]?._id
    };

    const { register, setValue, watch, control, reset, handleSubmit } = useForm<formValues>({
        defaultValues: {
            modifications: UnitModifcations ?? [modificationObj]
        }
    });

    useEffect(() => {
        reset({
            modifications: UnitModifcations
        });
    }, [UnitModifcations]);

    const onSubmit = (values: { [key: string]: any }) => {
        const data = {
            name: values?.name,
            date: dayjs(values?.date).format('DD/MM/YYYY'),
            modification_status: values?.modification_status,
            project_manager: values?.project_manager,
            client_manager: values?.client_manager,
            description: values?.description,
            unit_id: id
        };
        if (values?._id) {
            dispatch(PropertiesSagaActions.editPropertyUnitModification({ _id: values?._id, ...data }));
        } else {
            dispatch(PropertiesSagaActions.addPropertyUnitModification(data));
        }
    };

    const { fields, append, remove } = useFieldArray({
        name: 'modifications',
        control
    });

    const handleDeleteModification = ({ index, field }: { [key: string]: any }) => {
        dispatch(PropertiesReducerAction.setToggleDeleteModal(true));
        dispatch(PropertiesReducerAction.setSelectedUnitModification(field));
        setIndex(index);
    };
    return (
        <Grid className="modification-tab-container">
            <form>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    variant="scrollable"
                    sx={{
                        mb: 3,
                        '& a': {
                            minHeight: 'auto',
                            minWidth: 10,
                            py: 1.5,
                            px: 1,
                            mr: 2.25,
                            color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        },
                        '& .MuiTabs-scroller': {
                            height: 'fit-content'
                        },
                        '& a.Mui-selected': {
                            color: theme.palette.primary.main
                        },
                        '& .MuiTabs-indicator': {
                            bottom: 2
                        },
                        '& a > svg': {
                            marginBottom: '0px !important',
                            mr: 1.25
                        },
                        '& .MuiTabs-flexContainer': {
                            border: 'none !important'
                        }
                    }}
                >
                    {fields.map((field, index) => (
                        <Tab
                            icon={GalleryLight}
                            className="update-sub-header-icon-button"
                            key={index}
                            component={Link}
                            to="#"
                            label={field?.name || `Modification #${index + 1}`}
                            {...a11yProps(index)}
                        />
                    ))}
                    <Tab
                        className="update-sub-header-icon-button"
                        key={fields?.length + 1}
                        component={Link}
                        onClick={() => {
                            append(modificationObj);
                        }}
                        to="#"
                        icon={<PlaylistAddIcon />}
                        label={'Add Modification'}
                    />
                </Tabs>
                <Grid className="updates-data-container" item width={'100%'}>
                    {fields.map((field: { [key: string]: string }, index: number) => (
                        <TabPanel value={value} index={index}>
                            <div className="text-button-wrapper">
                                <ModificationForms
                                    field={field}
                                    managersList={managersList}
                                    clientsList={clientsList}
                                    register={register}
                                    setValue={setValue}
                                    watch={watch}
                                    control={control}
                                />
                                <Button
                                    className="modification-button"
                                    variant="contained"
                                    type="button"
                                    onClick={() => {
                                        handleDeleteModification({ field: field, index });
                                    }}
                                >
                                    Remove Modification
                                </Button>
                                <Grid marginY={'10px'} display={'flex'} gap={'20px'}>
                                    <Button variant="outlined" startIcon={<ReplyIcon className="back-icon" />} onClick={handleBack}>
                                        Back to Property
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        type="submit"
                                        onClick={handleSubmit((values) => onSubmit({ _id: field._id, ...values }))}
                                        startIcon={<ForwardOutlinedIcon className="forward-icon" />}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </div>
                        </TabPanel>
                    ))}
                </Grid>
            </form>
            <ConfirmationModal
                title="Are you sure you want to delete this Modification?"
                isOpen={isDeleteModalOpen ?? false}
                handleClose={handleClose}
                handleConfirm={handleDelete}
            />
        </Grid>
    );
};

export default ModificationTabs;
