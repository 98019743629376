import { Grid } from '@mui/material';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

export const dropDownHelper = [
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Main building
            </Grid>
        ),
        value: 0
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Golden Visa
            </Grid>
        ),
        value: 2
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Elina Costa
            </Grid>
        ),
        value: 3
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Portugal
            </Grid>
        ),
        value: 4
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Lebanese
            </Grid>
        ),
        value: 6
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />1 Parking
            </Grid>
        ),
        value: 7
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Storage
            </Grid>
        ),
        value: 8
    }
];

export const dropDownHelperInvestmentType = [
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Golden Visa
            </Grid>
        ),
        value: "Golden Visa"
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Residential
            </Grid>
        ),
        value: "Residential"
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Investment
            </Grid>
        ),
        value: "Investment"
    }
];

export const dropDownHelperParkingSpots = [
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />1 Parking
            </Grid>
        ),
        value: 0
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />2 Parking
            </Grid>
        ),
        value: 1
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />3 Parking
            </Grid>
        ),
        value: 2
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />4 Parking
            </Grid>
        ),
        value: 3
    }
];

export const dropDownHelperStorage = [
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Yes
            </Grid>
        ),
        value: 1
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                No
            </Grid>
        ),
        value: 0
    }
];
export const dropDownHelperStatus = [
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Available
            </Grid>
        ),
        value: 1
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Sold
            </Grid>
        ),
        value: 0
    }
];

export const dropDownHelperFinancial = [
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Paid
            </Grid>
        ),
        value: 0
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Pending
            </Grid>
        ),
        value: 1
    }
];

export const dropDownHelperModifications = [
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Pending
            </Grid>
        ),
        value: 0
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Project Manager
            </Grid>
        ),
        value: 1
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Client Manager
            </Grid>
        ),
        value: 2
    }
];

export enum fieldType {
    textField = 'textField',
    selectField = 'selectField',
    dateTime = 'dateTime',
    button = 'button'
}
