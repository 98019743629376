import { Grid } from '@mui/material';

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import UnitProfile from 'views/properties/Components/ProfileFormsTabs/PropertyUnits/ModalForms/UnitFormTabs/UnitProfile';
import FinancialsTab from 'views/properties/Components/ProfileFormsTabs/PropertyUnits/ModalForms/UnitFormTabs/FinancialTab';
import { TabPanel } from 'views/properties/utils';
import './style.scss';
import DocumentTabs from './UnitFormTabs/Documents';
import ModificationTab from '../UnitModificationForm/ModificationTab';
import { PropertiesSaga, PropertiesSagaActions, PropertiesSagaName } from 'store/properties/saga';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { PropertiesReducer, PropertiesReducerName } from 'store/properties/slice';
import { PropertyDetailsReducer, PropertyDetailsReducerName } from 'store/propertyDetails/slice';
import { PropertyDetailsSaga, PropertyDetailsSagaName } from 'store/propertyDetails/saga';

const UnitFormTabs = ({ value }: { value: number }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    useInjectReducer({
        reducer: PropertiesReducer,
        key: PropertiesReducerName
    });
    useInjectSaga({
        saga: PropertiesSaga,
        key: PropertiesSagaName
    });
    useInjectReducer({
        reducer: PropertyDetailsReducer,
        key: PropertyDetailsReducerName
    });
    useInjectSaga({
        saga: PropertyDetailsSaga,
        key: PropertyDetailsSagaName
    });
    useEffect(() => {
        id && dispatch(PropertiesSagaActions.fetchPropertyUnitById(id));
    }, [id]);

    return (
        <>
            <Grid padding="20px 20px 10px 20px" className=" property-forms-data-container" item width={'100%'}>
                <TabPanel value={value} index={0}>
                    <UnitProfile />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <FinancialsTab />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <ModificationTab />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <DocumentTabs />
                </TabPanel>
            </Grid>
        </>
    );
};

export default UnitFormTabs;
