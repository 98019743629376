import Box from '@mui/material/Box';

import { useEffect } from 'react';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReplyIcon from '@mui/icons-material/Reply';
import ForwardOutlinedIcon from '@mui/icons-material/ForwardOutlined';
import { Button, Grid, MenuItem, Select, TextField } from '@mui/material';
import { dropDownHelperInvestmentType, dropDownHelperParkingSpots, dropDownHelperStatus, dropDownHelperStorage } from './helper';
import { PropertiesSagaActions } from 'store/properties/saga';
import { propertyDetailsSelector, SelectedPropertyUnitSelector } from 'store/properties/selector';
import { Controller, useForm } from 'react-hook-form';
import { ClientsSagaActions } from 'store/clients/saga';
import { countriesListSelector } from 'store/clients/selector';
import { countriesListType } from 'store/clients/types';
import { PropertiesDataType } from 'store/properties/types';
import './style.scss';
import { PropertiesReducerAction } from 'store/properties/slice';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserSagaActions } from 'store/users/saga';
import { managersListSelector } from 'store/users/selector';
import { UserDetailsType } from 'store/users/types';
import UploadButton from 'ui-component/UploadButton/UploadButton';

export default function TextMobileStepper() {
    const { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const schema = yup.object().shape({
        unit_name: yup.string().required('Unit Name is required')
    });

    const handleBack = () => {
        navigate(-1);
        dispatch(PropertiesReducerAction.setSelectedPropertyUnit({}));
    };

    const selectedPropertyUnit = useSelector(SelectedPropertyUnitSelector);

    useEffect(() => {
        const getAPIsCall = async () => {
            await dispatch(ClientsSagaActions.getCountriesList());
            await dispatch(UserSagaActions.getUserByManagerRole());
        };
        getAPIsCall();
        id && dispatch(PropertiesSagaActions.fetchPropertyUnitById(id));
    }, [dispatch]);

    const propertyDetails: PropertiesDataType = useSelector(propertyDetailsSelector);
    const managersList: UserDetailsType[] = useSelector(managersListSelector);

    const countriesList = useSelector(countriesListSelector);
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset,
        control,
        setValue
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: selectedPropertyUnit && selectedPropertyUnit
    });

    const onSubmit = async (values: { [key: string]: any }) => {
        const data = {
            ...values,
            usable_area: parseInt(values?.usable_area),
            private_gross_area: parseInt(values?.private_gross_area),
            total_gross_area: parseInt(values?.total_gross_area),
            total_area: parseInt(values?.total_area),
            storage: values?.storage,
            properties_id: values?.properties_id || propertyDetails?._id,
            agent_name: values?.agent_name || 'Michel Thiran',
            client_name: values?.client_name || 'Michel Thiran'
        };
        if (id) {
            await dispatch(PropertiesSagaActions.editPropertyUnit(data));
        } else {
            console.log(data, 'data');
            await dispatch(PropertiesSagaActions.addPropertyUnit(data));
        }
        navigate(-1);
    };

    const propertySectionsList = [
        { id: 1, label: propertyDetails?.property_section_1 },
        { id: 2, label: propertyDetails?.property_section_2 },
        { id: 3, label: propertyDetails?.property_section_3 },
        { id: 4, label: propertyDetails?.property_section_4 }
    ];

    useEffect(() => {
        if (selectedPropertyUnit) {
            reset({
                ...selectedPropertyUnit,
                building: selectedPropertyUnit?.building || propertySectionsList[0]?.label,
                manager: selectedPropertyUnit?.manager ?? managersList[0]?._id,
                storage: selectedPropertyUnit?.storage,
                purpose: selectedPropertyUnit?.purpose,
                agent_nationality: selectedPropertyUnit.agent_nationality,
                client_nationality: selectedPropertyUnit.client_nationality
            });
        }
    }, [selectedPropertyUnit]);

    return (
        <form>
            <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
                <Box sx={{ height: '100%', maxWidth: '100%', width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid item marginBottom={'20px'} xs={12} className="media-upload">
                            <UploadButton setValue={setValue} field={'image'} register={register} watch={watch} />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('unit_name') }}
                                helperText={errors?.unit_name?.message?.toString()}
                                error={!!errors?.unit_name}
                                required
                                fullWidth
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Unit Name"
                                {...register('unit_name')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <Controller
                                control={control}
                                name="building"
                                render={({ field: { onChange, onBlur, value, ref } }) => {
                                    return (
                                        <Select
                                            key={value}
                                            labelId={value}
                                            onChange={onChange}
                                            value={value}
                                            ref={ref}
                                            onBlur={onBlur}
                                            sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                            defaultValue={propertySectionsList[0]?.label}
                                        >
                                            {propertySectionsList.map((option) => (
                                                <MenuItem key={option.id} value={option.label}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>{option.label}</div>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    );
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <Controller
                                control={control}
                                name="purpose"
                                render={({ field: { onChange, onBlur, value, ref } }) => {
                                    return (
                                        <Select
                                            key={value}
                                            labelId={value}
                                            onChange={onChange}
                                            value={value}
                                            ref={ref}
                                            onBlur={onBlur}
                                            sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                            defaultValue={value || dropDownHelperInvestmentType[0]?.value}
                                        >
                                            {dropDownHelperInvestmentType.map((option) => (
                                                <MenuItem value={option.value}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>{option.label}</div>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    );
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <Controller
                                control={control}
                                name="manager"
                                render={({ field: { onChange, onBlur, value, ref } }) => {
                                    return (
                                        <Select
                                            key={value}
                                            labelId={value}
                                            onChange={onChange}
                                            value={value}
                                            ref={ref}
                                            onBlur={onBlur}
                                            sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                            defaultValue={managersList[0]?._id}
                                        >
                                            {managersList?.map((option) => (
                                                <MenuItem value={option._id}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                        {option.first_name + ' ' + option.last_name}
                                                    </div>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    );
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <Controller
                                control={control}
                                name="status"
                                render={({ field: { onChange, onBlur, value, ref } }) => {
                                    return (
                                        <Select
                                            key={value}
                                            labelId={value}
                                            onChange={onChange}
                                            value={value}
                                            ref={ref}
                                            onBlur={onBlur}
                                            sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                            defaultValue={value || dropDownHelperStatus[0]?.value}
                                        >
                                            {dropDownHelperStatus.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>{option.label}</div>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <Controller
                                control={control}
                                name="agency"
                                render={({ field: { onChange, onBlur, value, ref } }) => {
                                    return (
                                        <Select
                                            key={value}
                                            labelId={value}
                                            onChange={onChange}
                                            value={value}
                                            ref={ref}
                                            onBlur={onBlur}
                                            sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                            defaultValue={value}
                                        >
                                            {[{ label: 'Agency', value: 'agency' }].map((option) => (
                                                <MenuItem value={option.value}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>{option.label}</div>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    );
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <Controller
                                control={control}
                                name="agent_name"
                                render={({ field: { onChange, onBlur, value, ref } }) => {
                                    return (
                                        <Select
                                            key={value}
                                            labelId={value}
                                            onChange={onChange}
                                            value={value}
                                            ref={ref}
                                            onBlur={onBlur}
                                            sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                            defaultValue={value}
                                        >
                                            {[{ label: 'Michel Thiran', value: 'Michel Thiran' }].map((option) => (
                                                <MenuItem value={option.value}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>{option.label}</div>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    );
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('agent_phone') }}
                                fullWidth
                                label="Agent Phone"
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                {...register('agent_phone')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('agent_email') }}
                                fullWidth
                                label="Agent email"
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                {...register('agent_email')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <Controller
                                control={control}
                                name="agent_nationality"
                                render={({ field: { onChange, onBlur, value, ref } }) => {
                                    return (
                                        <Select
                                            key={value}
                                            labelId={value}
                                            onChange={onChange}
                                            value={value}
                                            ref={ref}
                                            onBlur={onBlur}
                                            sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                            defaultValue={countriesList[0]?.nationality}
                                        >
                                            {countriesList?.map((option: countriesListType) => (
                                                <MenuItem key={option.code} value={option?.nationality}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                        {option?.nationality}
                                                    </div>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={3}
                        >
                            <Controller
                                control={control}
                                name="client_name"
                                render={({ field: { onChange, onBlur, value, ref } }) => {
                                    return (
                                        <Select
                                            key={value}
                                            labelId={value}
                                            onChange={onChange}
                                            value={value}
                                            ref={ref}
                                            onBlur={onBlur}
                                            sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                            defaultValue={value}
                                        >
                                            {[{ label: 'Michel Thiran', value: 'Michel Thiran' }].map((option) => (
                                                <MenuItem value={option.value}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>{option.label}</div>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    );
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={3}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('client_phone') }}
                                fullWidth
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Client Phone"
                                {...register('client_phone')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={3}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('client_email') }}
                                fullWidth
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Client Email"
                                {...register('client_email')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={3}
                        >
                            <Controller
                                control={control}
                                name="client_nationality"
                                render={({ field: { onChange, onBlur, value, ref } }) => {
                                    return (
                                        <Select
                                            key={value}
                                            labelId={value}
                                            onChange={onChange}
                                            value={value}
                                            ref={ref}
                                            onBlur={onBlur}
                                            sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                            defaultValue={countriesList[0]?.nationality}
                                        >
                                            {countriesList?.map((option: countriesListType) => (
                                                <MenuItem key={option.code} value={option?.nationality}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                        {option?.nationality}
                                                    </div>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('responsible_technician_name') }}
                                fullWidth
                                label="Responsible Technician"
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                {...register('responsible_technician_name')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('responsible_technician_phone') }}
                                fullWidth
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Responsible Technician Phone"
                                {...register('responsible_technician_phone')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('responsible_technician_email') }}
                                fullWidth
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Responsible Technician Email"
                                {...register('responsible_technician_email')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('fiscal_representative_name') }}
                                fullWidth
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Fiscal Representative"
                                {...register('fiscal_representative_name')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('fiscal_representative_phone') }}
                                fullWidth
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Responsible Technician Phone"
                                {...register('fiscal_representative_phone')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('fiscal_representative_email') }}
                                fullWidth
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Fiscal Representative Email"
                                {...register('fiscal_representative_email')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('legal_representative_name') }}
                                fullWidth
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Legal Representative"
                                {...register('legal_representative_name')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('legal_representative_phone') }}
                                fullWidth
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Legal Representative Phone"
                                {...register('legal_representative_phone')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('legal_representative_email') }}
                                fullWidth
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Legal Representative Email"
                                {...register('legal_representative_email')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('usable_area') }}
                                fullWidth
                                defaultValue={0}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                type="number"
                                label="Usable Area"
                                {...register('usable_area')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('private_gross_area') }}
                                fullWidth
                                defaultValue={0}
                                type="number"
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Private Gross Area"
                                {...register('private_gross_area')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('total_gross_area') }}
                                fullWidth
                                defaultValue={0}
                                type="number"
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Total Gross Area"
                                {...register('total_gross_area')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('total_area') }}
                                fullWidth
                                defaultValue={0}
                                type="number"
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Area Total"
                                {...register('total_area')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('m2_value') }}
                                fullWidth
                                label="M2 Value"
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                {...register('m2_value')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={6}
                        >
                            <Controller
                                control={control}
                                name="parking_spaces"
                                render={({ field: { onChange, onBlur, value, ref } }) => {
                                    return (
                                        <Select
                                            key={value}
                                            labelId={value}
                                            onChange={onChange}
                                            ref={ref}
                                            onBlur={onBlur}
                                            value={value || dropDownHelperParkingSpots[0]?.value}
                                            sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                        >
                                            {dropDownHelperParkingSpots.map((option) => (
                                                <MenuItem value={option.value}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>{option.label}</div>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    );
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={6}
                        >
                            <Controller
                                control={control}
                                name="storage"
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <Select
                                        key={value}
                                        labelId={value}
                                        onChange={onChange}
                                        ref={ref}
                                        onBlur={onBlur}
                                        value={value || dropDownHelperStorage[0]?.value}
                                        sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                    >
                                        {dropDownHelperStorage.map((option) => (
                                            <MenuItem value={option.value}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>{option.label}</div>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid marginY={'10px'} display={'flex'} gap={'20px'}>
                        <Button variant="outlined" startIcon={<ReplyIcon className="back-icon" />} onClick={handleBack}>
                            Back to Property
                        </Button>
                        <Button
                            variant="outlined"
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                            startIcon={<ForwardOutlinedIcon className="forward-icon" />}
                        >
                            Save
                        </Button>
                    </Grid>
                </Box>
            </Box>
        </form>
    );
}
