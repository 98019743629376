import { useEffect } from 'react';
import { Button, Grid, IconButton, Typography } from '@mui/material';
// import { useDropzone } from 'react-dropzone';
import DropboxChooser from 'react-dropbox-chooser';

import color from 'assets/scss/_themes-vars.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { ClipAttachmentsIcon, DropBoxIcon } from 'ui-component/Icons';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { SelectedPropertyUnitSelector } from 'store/properties/selector';
import { PropertyDetailsSagaActions } from 'store/propertyDetails/saga';
import { useParams } from 'react-router-dom';
import { IsLoadingSelector, PropertiesDetailsDocumentListSelector } from 'store/propertyDetails/selector';
import UpdateCardsSkeleton from 'ui-component/cards/Skeleton/UpdatesCardsSkeleton';
const APP_KEY = 'gyas8mkefhspeus';

const DocumentTabs = () => {
    const BGArray = ['#8B6F4E', '#5B4933'];
    const dispatch = useDispatch();
    const { id } = useParams();
    const isLoading = useSelector(IsLoadingSelector);
    const propertiesDetailsDocumentList = useSelector(PropertiesDetailsDocumentListSelector);
    const selectedUnit = useSelector(SelectedPropertyUnitSelector);
    useEffect(() => {
        if (id) dispatch(PropertyDetailsSagaActions.getPropertyDocuments({ propertyId: selectedUnit?.properties_id, unit_ids: id }));
    }, [id]);

    const onSuccess = async (files: any) => {
        const payload = {
            dropbox_info: { ...files[0] },
            property_id: selectedUnit?.properties_id,
            unit_ids: selectedUnit?._id
        };
        await dispatch(PropertyDetailsSagaActions.addPropertyDocuments(payload));
    };

    const removeImageHandler = (documentId: string) => {
        dispatch(PropertyDetailsSagaActions.deletePropertyDocuments({ id: documentId, propertyId: selectedUnit?.propert_id }));
    };

    if (isLoading) {
        return <UpdateCardsSkeleton />;
    }

    return (
        <Grid
            style={{ minHeight: 'calc(100vh - 478px)' }}
            item
            className="input-property-forms drop-zone-SJ"
            xs={12}
            md={12}
            marginTop={'40px'}
        >
            <Grid className="drop-zone-division" display={'flex'} flexDirection={'column'} gap={'20px'}>
                <Grid item className="custom-drop-zone-container" xs={12} md={12}>
                    <DropboxChooser
                        appKey={APP_KEY}
                        multiselect={false}
                        success={(files: any) => onSuccess(files)}
                    >
                        <Button variant="contained" component="label" style={{ background: '#fafafa', color: 'black', gap: '5px' }}>
                            <DropBoxIcon />
                            Choose from Dropbox
                        </Button>
                    </DropboxChooser>
                </Grid>
                <Grid display={'flex'} gap={'10px'}>
                    <ClipAttachmentsIcon />
                    <Typography variant="h3">{propertiesDetailsDocumentList?.length} Attachment{propertiesDetailsDocumentList?.length > 1 && 's'}</Typography>
                </Grid>
                <Grid className="theGapClass" display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} width={'100%'} flexWrap={'wrap'}>
                    {!isLoading && propertiesDetailsDocumentList?.length === 0 && (
                        <Grid display={'flex'}>No Attachments found! Add documents to view them here.</Grid>
                    )}
                    {propertiesDetailsDocumentList?.map((file: any) => (
                        <Grid
                            item
                            xs={12}
                            sm={5.8}
                            md={5.8}
                            xl={2.84}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            className="propertyCardViewInnerCards"
                            flexWrap={'wrap'}
                            position={"relative"}
                            key={file?.name}
                        >
                            <div style={{ position: 'absolute', top: '6px', right: '6px', zIndex: 10 }}>
                                <IconButton style={{ backgroundColor: 'white' }} onClick={() => removeImageHandler(file._id)}>
                                    <CloseIcon fontSize="small" style={{ color: 'black', height: 20, width: 20 }} />
                                </IconButton>
                            </div>
                            <div
                                className="thumbnails-division"
                                style={{
                                    display: 'flex',
                                    backgroundColor: BGArray[Math.floor(Math.random() * BGArray.length)],
                                    height: '25vh',
                                    borderRadius: '12px 12px 0px 0px'
                                }}
                            >
                                {file?.dropbox_info?.thumbnailLink && (
                                    <img
                                        src={file?.dropbox_info?.thumbnailLink}
                                        alt={file?.dropbox_info?.name}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '12px 12px 0px 0px'
                                        }}
                                    />
                                )}
                            </div>
                            <div
                                style={{
                                    padding: '12px',
                                    backgroundColor: color.secondaryLight,
                                    borderRadius: '0px 0px 12px 12px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    width: '100%'
                                }}
                            >
                                {file?.dropbox_info?.name}
                            </div>
                        </Grid>
                    ))}
                </Grid>
                {/* <Grid item className="custom-drop-zone-container" xs={12} md={12}>
                    <Grid item className="custom-drop-zone" xs={12} md={12}>
                        <div {...getRootProps({ className: `${isDragActive ? 'drag-active' : ''} drop-zone` })}>
                            <input {...getInputProps()} />
                            <Button style={{ backgroundColor: color.primaryMain, color: 'white' }}>Add Documents</Button>
                        </div>
                    </Grid>
                </Grid> */}
            </Grid>
        </Grid>
    );
};

export default DocumentTabs;
