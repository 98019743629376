import { Grid } from '@mui/material';

import FormSteps from './FormSteps';

const UnitFormTabs = () => {
    return (
        <Grid item className="media-upload modal-form-upload">
            {/* <UploadButton src={mockImageHelper('1SqgLT2DXQ3Ck4299cAveqwdM_1srfvvs')} /> */}
            <Grid marginTop={'30px'}>
                <FormSteps />
            </Grid>
        </Grid>
    );
};

export default UnitFormTabs;
