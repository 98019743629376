import { createSlice } from '@reduxjs/toolkit';
import { MenuProps } from './types';

export const initialState: MenuProps = {
    selectedItem: [''],
    selectedID: null,
    drawerOpen: false,
    error: null
};

const sidepanel = createSlice({
    name: 'sidepanelState',
    initialState,
    reducers: {
        activeItem(state, action) {
            state.selectedItem = action.payload;
        },

        activeID(state, action) {
            state.selectedID = action.payload;
        },

        openDrawer(state, action) {
            state.drawerOpen = action.payload;
        },

        // has error
        hasError(state, action) {
            state.error = action.payload;
        }
    }
});

export const { actions: SidepanelReducerAction, name: SidepanelReducerName, reducer: SidepanelReducer } = sidepanel;
