// tabs option
import { BuilderIconDark, DevelopmentDark, DocumentFilesIconWhite, GalleryLight } from 'ui-component/Icons'; // GalleryLight

export const tabsOption = [
    {
        label: 'Unit Profile',
        icon: BuilderIconDark
    },
    {
        label: 'Financials',
        icon: DevelopmentDark
    },
    {
        label: 'Modifications',
        icon: GalleryLight
    },
    {
        label: 'Documents',
        icon: DocumentFilesIconWhite()
    }
];
