const Logo = () => {
    return (
        <svg width="89" height="42" viewBox="0 0 89 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1374_43)">
                <path d="M31.1652 13.8776V28.1246L18.5731 35.247V41.9339L37.0754 31.4664V10.5347L31.1652 13.8776Z" fill="#8B6F4B" />
                <path
                    d="M18.5731 42C18.5613 42 18.5495 41.9965 18.5389 41.9907C18.5176 41.9791 18.5058 41.9594 18.5058 41.934V35.2458C18.5058 35.2238 18.5176 35.1995 18.5389 35.1891L31.0978 28.0841V13.8765C31.0978 13.851 31.1108 13.829 31.1321 13.8163L37.0387 10.4745C37.0624 10.4629 37.086 10.4629 37.1096 10.4745C37.1297 10.4884 37.1415 10.5081 37.1415 10.5324V31.4676C37.1415 31.4884 37.1297 31.5116 37.1096 31.5231L18.6062 41.9907C18.5968 41.9977 18.585 42 18.5731 42ZM18.6417 35.2875V41.817L37.0068 31.4282V10.6482L31.2337 13.9158V28.1235C31.2337 28.1478 31.2207 28.1698 31.2006 28.1802L18.6417 35.2875Z"
                    fill="#8B6F4B"
                />
                <path
                    d="M5.9799 13.9412C5.96808 13.9412 5.9539 13.9401 5.94562 13.9331L0.0354539 10.5914C0.0129998 10.5798 0 10.5578 0 10.5335C0 10.5092 0.0129998 10.4895 0.0354539 10.4744L18.5389 0.00917267C18.5625 -0.00240651 18.5849 -0.00240651 18.6062 0.00917267L37.1073 10.4756C37.1297 10.4895 37.1404 10.5092 37.1404 10.5346C37.1404 10.5601 37.1285 10.5798 37.1073 10.5925L31.2006 13.9355C31.1794 13.947 31.1534 13.947 31.1309 13.9355L18.5719 6.82931L6.01299 13.9355C6.00235 13.9424 5.99053 13.9436 5.9799 13.9436M0.205633 10.5335L5.97871 13.7977L18.5377 6.69383C18.5589 6.68225 18.5838 6.68225 18.605 6.69383L31.164 13.7977L36.9371 10.5335L18.5719 0.141175L0.205633 10.5335Z"
                    fill="#8B6F4B"
                />
                <path
                    d="M18.5731 42C18.5625 42 18.5495 41.9965 18.5389 41.9907L0.0354539 31.5231C0.011818 31.5127 0 31.4896 0 31.4676V10.5336C0 10.5081 0.011818 10.4884 0.0354539 10.4745C0.0579081 10.4629 0.0839076 10.4629 0.102816 10.4745L6.0118 13.8163C6.03426 13.829 6.04608 13.851 6.04608 13.8765V28.0864L18.6039 35.188C18.6263 35.1984 18.6405 35.2215 18.6405 35.2458V41.934C18.6405 41.9594 18.6263 41.9791 18.605 41.9907C18.5968 41.9977 18.5838 42 18.5719 42M0.134725 31.4282L18.5046 41.817V35.2875L5.94444 28.1814C5.92199 28.1721 5.91017 28.1501 5.91017 28.1258V13.917L0.134725 10.6482V31.427V31.4282Z"
                    fill="#8B6F4B"
                />
                <path
                    d="M31.1652 28.1883C31.1534 28.1883 31.1439 28.1883 31.1309 28.1802L24.8367 24.6196C24.8154 24.608 24.8036 24.5849 24.8036 24.5629V17.4752L18.5389 13.9308C18.5176 13.9193 18.5058 13.8984 18.5058 13.8741V6.74944C18.5058 6.72397 18.5176 6.70312 18.5389 6.69155C18.5601 6.67997 18.5861 6.67997 18.6062 6.69155L31.2006 13.8151C31.2219 13.8266 31.2325 13.8498 31.2325 13.8741V28.1235C31.2325 28.1466 31.2219 28.1686 31.2006 28.179C31.19 28.186 31.177 28.1871 31.1652 28.1871M24.9383 24.5235L31.0978 28.0077V13.9158L18.6405 6.86639V13.8359L24.9052 17.3768C24.9265 17.3896 24.9383 17.4115 24.9383 17.437V24.5246V24.5235Z"
                    fill="#8B6F4B"
                />
                <path
                    d="M18.5731 35.3143C18.5625 35.3143 18.5471 35.3097 18.5389 35.3062L5.94208 28.1815C5.92317 28.1723 5.90899 28.1503 5.90899 28.1236C5.90899 28.1005 5.92317 28.0773 5.94208 28.0646L12.2422 24.5028C12.2635 24.4912 12.2872 24.4912 12.3084 24.5028L18.5731 28.0461L24.8378 24.5028C24.8579 24.4912 24.8827 24.4912 24.904 24.5028L31.2006 28.0646C31.2207 28.0762 31.2337 28.0993 31.2337 28.1236C31.2337 28.148 31.2195 28.1688 31.2006 28.1804L18.6062 35.3062C18.5968 35.3108 18.585 35.3143 18.5731 35.3143ZM6.11462 28.1236L18.5731 35.1684L31.0316 28.1236L24.8698 24.6383L18.6062 28.1804C18.585 28.192 18.559 28.192 18.5389 28.1804L12.2753 24.6383L6.11462 28.1236Z"
                    fill="#8B6F4B"
                />
                <path d="M18.5731 13.8754V6.75073L5.9799 13.8777V28.1259L12.2753 24.5641V17.4372L18.5731 13.8754Z" fill="#8B6F4B" />
                <path d="M18.5731 20.9976V28.1246L24.8697 24.5617V17.437L18.5731 20.9976Z" fill="#8B6F4B" />
                <path
                    d="M18.5731 28.1884C18.5353 28.1884 18.5058 28.1606 18.5058 28.1247V21C18.5058 20.9618 18.5353 20.9329 18.5731 20.9329C18.611 20.9329 18.6417 20.9618 18.6417 21V28.1247C18.6417 28.1617 18.6121 28.1884 18.5731 28.1884Z"
                    fill="#8B6F4B"
                />
                <path
                    d="M18.5731 21.066C18.5625 21.066 18.5471 21.0602 18.5389 21.0544L12.2422 17.4938C12.2186 17.4846 12.208 17.4603 12.208 17.4371C12.208 17.4116 12.2186 17.3908 12.2422 17.3769L18.5389 13.8163C18.5601 13.8047 18.5861 13.8047 18.6062 13.8163L24.904 17.3769C24.9265 17.3896 24.9383 17.4116 24.9383 17.4371C24.9383 17.4603 24.9265 17.4834 24.904 17.4938L18.6062 21.0544C18.5979 21.0614 18.5849 21.066 18.5731 21.066ZM12.4101 17.4371L18.5731 20.9213L24.735 17.4371L18.5731 13.9529L12.4101 17.4371Z"
                    fill="#8B6F4B"
                />
                <path d="M56.2654 25.5506H54.6274V17.9674H51.6954V16.4922H59.1974V17.9674H56.2654V25.5506Z" fill="#8B6F4B" />
                <path
                    d="M64.8251 25.7057C61.9853 25.7057 60.0046 23.5972 60.0046 21.0335C60.0046 18.4699 62.0101 16.3347 64.8511 16.3347C67.6922 16.3347 69.6717 18.4444 69.6717 21.0081C69.6717 23.5717 67.6638 25.7057 64.824 25.7057M64.824 17.7983C62.9874 17.7983 61.7064 19.2353 61.7064 21.0081C61.7064 22.7808 63.0134 24.2445 64.85 24.2445C66.6865 24.2445 67.9664 22.8075 67.9664 21.0335C67.9664 19.2596 66.6569 17.7995 64.8228 17.7995"
                    fill="#8B6F4B"
                />
                <path
                    d="M78.1026 16.4922H79.6993V25.5506H78.3378L73.2407 19.1045V25.5506H71.6406V16.4922H73.1474L78.1026 22.7565V16.4922Z"
                    fill="#8B6F4B"
                />
                <path
                    d="M82.0758 16.4922V25.5506H89V24.1263H83.702V21.6936H88.3382V20.2705H83.702V17.913H88.9326V16.4922H82.0758Z"
                    fill="#8B6F4B"
                />
                <path
                    d="M47.6678 20.2949C49.6237 20.7615 50.64 21.4482 50.64 22.9616C50.64 24.6684 49.2821 25.6804 47.3381 25.6804C45.9258 25.6804 44.5892 25.201 43.4819 24.2307L44.4592 23.0901C45.3456 23.8393 46.2296 24.2689 47.3759 24.2689C48.3828 24.2689 49.0151 23.8138 49.0151 23.1179C49.0151 22.4579 48.6452 22.107 46.9292 21.7203C44.9615 21.2537 43.8482 20.6816 43.8482 19.0015C43.8482 17.4209 45.1707 16.3591 47.0072 16.3591C48.3556 16.3591 49.4251 16.7632 50.3611 17.4997L49.4901 18.7051C48.6582 18.0948 47.8262 17.7741 46.9776 17.7741C46.0298 17.7741 45.4744 18.2512 45.4744 18.8591C45.4744 19.5724 45.8963 19.8792 47.6666 20.2949"
                    fill="#8B6F4B"
                />
            </g>
            <defs>
                <clipPath id="clip0_1374_43">
                    <rect width="89" height="42" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Logo;
